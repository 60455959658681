import React, { useState, useEffect, useContext } from "react";

import axios from 'axios';
import { UserContext } from '../contexts/UserContext';

import './ShitPicker.css';

export function ShitPickerEvent({icon, type, count, opponentCount, onClick}) {

  return (
    <div className="shit-picker-event">
    <div className="inline">
      <div className="icon">{icon}</div>
      <div className="count">{count}</div>
      <div className="opponent-count">{opponentCount}</div>
      <button className="add-button" onClick={onClick}>+</button>
    </div>
    </div>
  );
};

export default function ShitPicker(props) {

  const user = useContext(UserContext);
  const [counter, setCounter] = useState(0);
  const [myResult, setMyResult] = useState({
    user: {},
    shitPicker: {count: 0},
    softShitPicker: {count: 0},
    wateryShitPicker: {count: 0},
    nightShitPicker: {count: 0},
    nightSoftShitPicker: {count: 0},
    nightWateryShitPicker: {count: 0}
  });
  const [opponentResult, setOpponentResult] = useState({
    user: {},
    shitPicker: {count: 0},
    softShitPicker: {count: 0},
    wateryShitPicker: {count: 0},
    nightShitPicker: {count: 0},
    nightSoftShitPicker: {count: 0},
    nightWateryShitPicker: {count: 0}
  });
  
  const refreshResult = () => {
    const opponentId = user.id === 1 ? 2 : 1
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/event/shitpicker?opponentId=${opponentId}`, {
        withCredentials: true
      })
    .then(response => {
        console.log(response.data)
        setMyResult(response.data.user)
        setOpponentResult(response.data.opponent)
    })
    .catch(function (error) {
        console.log(error);
    })  
  }

  const submitShitPickerEvent = (shitType) => {
    const opponentId = user.id === 1 ? 2 : 1
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/event/shitpicker`, {
        event_type: shitType,
        reportee: opponentId
    }, {
        withCredentials: true
    })
    .then(response => {
        refreshResult()
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  useEffect(() => {
    refreshResult();
  }, []);
  
  return (
    <div className="shit-picker">
      <div className="events">

        <div className="event">
          <div className="inner">
          <div className="shit-picker-event">
            <div className="inline">
              <div className="icon">Type</div>
              <div className="count">J</div>
              <div className="opponent-count">N</div>
              <div className="add-button" />
            </div>
          </div>
          </div>
        </div>
 
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="☀️💩"
            type="shitPicker"
            count={myResult.shitPicker.count}
            opponentCount={opponentResult.shitPicker.count}
            onClick={() => submitShitPickerEvent('shit-picker')}
          />
          </div>
        </div>
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="☀️🍦💩"
            type="softShitPicker"
            count={myResult.shitPicker.count}
            opponentCount={opponentResult.softShitPicker.count}
            onClick={() => submitShitPickerEvent('soft-shit-picker')}
          />
          </div>
        </div>
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="☀️💧💩"
            type="wateryShitPicker"
            count={myResult.wateryShitPicker.count}
            opponentCount={opponentResult.wateryShitPicker.count}
            onClick={() => submitShitPickerEvent('watery-shit-picker')}
          />
          </div>
        </div>
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="🌙💩"
            type="nightShitPicker"
            count={myResult.nightShitPicker.count}
            opponentCount={opponentResult.nightShitPicker.count}
            onClick={() => submitShitPickerEvent('night-shit-picker')}
          />
          </div>
        </div>
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="🌙🍦💩"
            type="nightSoftShitPicker"
            count={myResult.nightSoftShitPicker.count}
            opponentCount={opponentResult.nightSoftShitPicker.count}
            onClick={() => submitShitPickerEvent('night-soft-shit-picker')}
          />
          </div>
        </div>
        <div className="event">
          <div className="inner">
          <ShitPickerEvent
            icon="🌙💧💩"
            type="nightWateryShitPicker"
            count={myResult.nightWateryShitPicker.count}
            opponentCount={opponentResult.nightWateryShitPicker.count}
            onClick={() => submitShitPickerEvent('night-watery-shit-picker')}
          />
          </div>
        </div>

      </div>
    </div>
  );
}
