import { useState, useEffect } from 'react';
import axios from 'axios';
import Pound from './Pound';

import './Debt.css';

export default function Debt() {

  const [data, setData] = useState({debt: null, total_repayment: null, history: []})
  const [amount, setAmount] = useState(null)
  const otherUserId = 2

  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/debt/history/${otherUserId}`, {
      withCredentials: true
    })
    .then(response => {
      setData(response.data)
    })
  };

  const calculateMonthlyInterest = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/debt/interest`, { creditor_id: otherUserId }, {
      withCredentials: true
    })
    .then(response => {
      fetchData();
    });
  };

  const submitRepayment = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/debt/repayment`, { creditor_id: otherUserId, amount: amount }, {
      withCredentials: true
    })
    .then(response => {
      fetchData();
    });
  }

  useEffect(() => { fetchData() }, []);

  return (
    <div className="debt">
      <div className="input-group">
        <div className="input-row inline last">
          <input placeholder="Todo..." value={amount} onChange={(e) => setAmount(e.target.value)}/>
          <div className="right-button">
          <button onClick={() => submitRepayment()}><icon>➕</icon></button>
          </div>
        </div>
      </div>



      <div className="info">
        <button onClick={calculateMonthlyInterest}>Calcluate Monthly Interest</button>
      </div>

      <div className="info debts">
        <div className="title">Debt:</div>
        <div className="content">
          <Pound amount={-parseFloat(data.debt)}/>
        </div>
      </div>

      <div className="info total-repayment">
        <div className="title">Total Repayment:</div>
        <div className="content">
          <Pound amount={parseFloat(data.total_repayment)} />
        </div>
      </div>

      <div className="history">
        {(data.history.map((item) => (
          <div className={("item " + item.type.toLowerCase())}>
            <div className="left">
            <div className="date">{item.created_at.split("T")[0]}</div>
            <div className="note">{item.note}</div>
            </div>
            <div className="right">
            <div className="amount"><Pound amount={parseFloat(item.amount) * (item.type == "Repayment" ? 1 : -1)}/></div>
            </div>
          </div>
        )))}
      </div>
    </div>
  ); 
}
