import { Switch, Route } from "react-router-dom";
import '../styles/App.css';
import Dashboard from "./Dashboard";
import Pigou from "./Pigou";
import Nichijou from "./Nichijou"
import Scoreboard from "./Scoreboard";
import ShitPicker from "./ShitPicker";
import CookOff from "./CookOff";
import Home from "./Home";
import Todo from "./Todo";
import Exercise from "../exercise/Exercise";
import Debt from "../components/Debt";
import Sample from "./Sample";
import Anniversary from "./Anniversary";
import JapaneseQuiz from "./JapaneseQuiz";
import PrivateMessage from "./PrivateMessage";

export default function Router({children, logout}) {

  return (
    <Switch>
      <Route exact path={"/"}
              render={props => (<Home />)}/>
      <Route exact
        path={"/dashboard"}
        render={props => (<Dashboard handleLogout={logout}/>)}
      />
      <Route exact
        path={"/scoreboard"}
        render={props => (<Scoreboard />)}
      />
      <Route exact
        path={"/todo"}
        render={props => (<Todo />)}
      />
      <Route exact
        path={"/cookoff"}
        render={props => (<CookOff />)}
      />
      <Route exact
        path={"/shitPicker"}
        render={props => (<ShitPicker />)}
      />
      <Route exact 
        path={"/pigou"}
        render={props => (<Pigou />)}
      />
      <Route exact
        path={"/nichijou"}
        render={props => (<Nichijou />)}
      />
      <Route exact
        path={"/exercise"}
        render={props => (<Exercise />)}
      />
      <Route exact
        path={"/debt"}
        render={props => (<Debt />)}
      />
      <Route exact
        path={"/sample"}
        render={props => (<Sample />)}
      />
      <Route exact
        path={"/anniversary"}
        render={props => (<Anniversary />)}
      />
      <Route exact
        path={"/juiz"}
        render={props => (<JapaneseQuiz />)}
      />
      <Route exact
        path={"/pm"}
        render={props => (<PrivateMessage/>)}
      />
    </Switch>
  );
}
