import { useState, useEffect, createContext } from 'react';
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';

const UserContext = createContext();

const UserContextProvider = ({children, logout}) => {

  const [user, setUser] = useState({id: null});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/identity`, {
      withCredentials: true
    }).then(response => {
      if (response.status == 200 && response.data) {
        console.log(response.data)
        setUser(response.data);
      } else {
        console.log("login error", )
        logout();
      }
    }).catch(error => {
      console.log("login error", error)
      logout()
    }).finally(() => {
      setLoaded(true);
    });
  }, []);

  const [websocket, setWebsocket] = useState(null);

  useEffect(() => {
    // Create a new WebSocket connection when the component mounts
    const ws = new WebSocket(`${process.env.REACT_APP_PUSHIE_URL}/message/${user.id}`);

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      toast(event.data);
      console.log('Message:', event);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket closed');
    };

    // Save the WebSocket object in state
    setWebsocket(ws);

    // Close the WebSocket connection when the component unmounts
    return () => {
      if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
        ws.close();
      }
    };
  }, [user]);

  if (!loaded) {
    return <div>Loading</div>
  } else if (user.id == null) {
    return <Redirect to='https://auth.mutantbutton2.com'/>
  } else {
    return (
      <UserContext.Provider value={user}>
        {children}
      </UserContext.Provider>
    );
  }
};

export { UserContext, UserContextProvider };
