import { useState, useEffect } from 'react';
import { Button }  from '../components/Core';
import toast from 'react-hot-toast';

export default function Sample() {

  const [buttonBackgroundColorNormal, setButtonBackgroundColorNormal] = useState(null)
  const [buttonColor, setButtonColor] = useState(null)

  const onButtonBackgroundColorNormalChange = (e) => {
    setButtonBackgroundColorNormal(e.target.value)
  }

  const onButtonColorChange = (e) => {
    setButtonColor(e.target.value)
  }

  const onButtonClick = (e) => {
    toast('Hello', 5000)
  }

  return (
      <div className="sample">
        <Button backgroundColor={"#" + buttonBackgroundColorNormal} color={"#" + buttonColor}>
          Button Sample
        </Button>
        <input type="text" id="fname" name="fname" value={buttonBackgroundColorNormal} onChange={onButtonBackgroundColorNormalChange} />
        <input type="text" id="fname" name="fname" value={buttonColor} onChange={onButtonColorChange} />
        <Button onClick={onButtonClick}> Test Toast </Button>
      </div>
  );
}
