import { useState, useEffect } from 'react';
import { Button }  from '../components/Core';
import Juiz from '../components/Juiz/Juiz';

export default function JapaneseQuiz() {

  return (
      <Juiz 
        choices={[
          'ぶ', 'だい', 'はい', 'ぱい', 'ばい', 'ひき', 'ぴき', 'びき', 'ほん', 'ぽん', 'ぼん', 'かい', 'がい', 'こ', 'まい', 'めん', 'めい', 'にん', 'り', 'さい', 'さつ', 'つ', 'わ', 'びょう', 'ふん', 'ぷん', 'がつ', 'つき', 'はく', 'ぱく', 'じ', 'じかん', 'か', 'かげつ', 'ねん', 'にち', 'しゅう', 'ばい', 'ばん', 'ど', 'たび', 'じょう', 'かい' 
        ]}
        questions={[
          {
            object: '消しゴム',
            amount: '三',
            answer: 'こ',
          },
          {
            object: '消しゴム',
            amount: '三',
            answer: 'こ',
          },
          {
            object: '鉛筆',
            amount: '一',
            answer: 'ぽん',
          },
          {
            object: '写真',
            amount: '三',
            answer: 'まい',
          },
          {
            object: '犬',
            amount: '三',
            answer: 'びき',
          }
      ]}/>
  );
}
