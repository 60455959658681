import { useState, useEffect } from "react";

import { Layout } from '../components/Layout';

import Item from "../todo/Item";
import PullToRefresh from 'react-simple-pull-to-refresh';
import axios from 'axios'

import '../components/Todo.css';

export default function Todo(props) {

  const group = 1
  const [inputValue, setInputValue] = useState('');
  const result = {
        user1: {username: 'mutantbutton2'},
        user2: {username: 'metamorphosis'},
  }
  const [items, setItems] = useState([]);
  const [shownItems, setShownItems] = useState([]);
  const [showDoneItems, setShowDoneItems] = useState(false);

  useEffect(() => {
    refreshResult()
  }, []);

  useEffect(() => { 
    setShownItems(items.filter(item => !showDoneItems ? true : item.completed_at == null))
  }, [items, showDoneItems])

  const updateInputValue = (event) => {
    setInputValue(event.target.value);
  }

  const toggleShowDownItems = () => {
    setShowDoneItems(!showDoneItems)
  }

  const refreshResult = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/todo/items?group=${group}`, {
        withCredentials: true
      })
    .then(response => {
        console.log(response.data)
        // handle success
        setItems(response.data.items);
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })  
  }

  const addTodoItem = (description) => {
    if (description == null || description == "")
      return
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/todo/items`, {
        group: group,
        description: description
    }, {
        withCredentials: true
    })
    .then(response => { refreshResult() })
    .catch(error => { console.log(error); })
  }

  return (
    <div className="todo">

      <div className="input-group">
        <div className="input-row inline last">
          <input placeholder="Todo..." value={inputValue} onChange={updateInputValue} />
          <div className="right-button">
          <button onClick={() => addTodoItem(inputValue)}><icon>➕</icon></button>
          </div>
        </div>
      </div>

      <div className="button-group">
        <button onClick={toggleShowDownItems}>Toggle Done Items</button>
      </div>

      {shownItems.map((item) => <Item key={item.id} item={item} refresh={refreshResult} />) }
    </div>
  );
}
