import { Layout } from '../components/Layout';
import Randomizer from "../randomizer/Randomizer";

export default function CookOff() {

  const items = [
    {
      category: 'Noodle',
      options: ['粗烏冬', '細烏冬', '素麵', '出前一丁', '細蓉麵', 'Tangliatelle', 'Spaghetti']
    },
    {
      category: 'Meat',
      options: ['Beef', 'Chicken', 'Seabass', 'Prawn', 'Mackerel', 'Lamb', 'Squid', 'Duck']
    },
    {
      category: 'Veggie',
      options: ['Brocolli', '白菜', 'Cabbage', 'Sprout', 'Potato', 'Sweet Potato', 'Tomato', 'Corn']
    },
    {
      category: 'Genre',
      options: ['中','日','美', '法', '英', '意', '德', '韓', '西']
    },
  ];

  return (
      <div className="button-group">
        {items.map((item) => (
          <div className="row">
            <Randomizer category={item.category} options={item.options} />
          </div>
        ))}
      </div>
  );
}
