import { useState } from 'react';

import './Match.css';

export default function Match({match}) {
  const [isOpen, setIsOpen] = useState(false);

  const MatchDetail = () => (
        <div className='match-detail'>
          {match.user1.achievements.map((achievement, index) => (
            <div className="each">
              <div className="reason">{match.user1.achievements[index].reason}</div>
              <div className="kill-extra">{match.user1.achievements[index].kill_extra} - {match.user2.achievements[index].kill_extra}</div>
              <div className="amount">{match.user1.achievements[index].amount} - {match.user2.achievements[index].amount}</div>
            </div>
          ))}
        </div>
  )

  return (
    <div>
      <div class='match' onClick={() => {setIsOpen(!isOpen);}}>
          <div xs={3} className="Col">
            <div>{match.user1.champion}</div>
            <div>{match.user1.kills} / {match.user1.deaths} / {match.user1.assists}</div>        
          </div>
          <div className="Col">
            <div className="score">
              <div className="score-item">{match.user1.total_score}</div>
              <div className="score-item">{match.user1.total_score - match.user2.total_score}</div>
              <div className="score-item">{match.user2.total_score}</div>
            </div>
          </div>
          <div xs={3} className="Col">
            <div>{match.user2.champion}</div>
            <div>{match.user2.kills} / {match.user2.deaths} / {match.user2.assists}</div>
          </div>
      </div>

      <div className="match">
        { isOpen ? <MatchDetail /> : null }
      </div>
    </div>
  );
}
