import { React, useState, useEffect } from 'react';

import './Juiz.css';
export default function Juiz({choices, questions}) {

  const [data, setData] = useState({
    question: 0,
    answered: 0,
    correct: 0,
  });

  const shuffle = (array) => { 
    for (let i = array.length - 1; i > 0; i--) { 
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  }; 

  const randomizeChoices = (_question, _choices, _num) => {
    const result = shuffle(_choices).slice(0,3)
    result.push(_question.answer)
    shuffle(result)
    return {
      answer: _question.answer,
      choices: result,
    }
  }

  const submitChoice = (choice) => {
    console.log("Picking: ", choice);
    let correct = (choice == questions[data.question].answer)
    setData({...data, question: (data.question + 1) % questions.length, answered: data.answered + 1, correct: data.correct + (correct ? 1 : 0) })
  }

  useEffect(() => {
    console.log(data)
  }, [data]);

  return (
    <div className="juiz" style={{ 'color':'#3a3a3a' }}>
      <div className="juiz-info">
        {data.correct}/{data.answered}
      </div>
      <div className="juiz-question">
        <div className="juiz-question-object">
        {questions[data.question].object}
        </div>

        <div className="juiz-question-amount">
          {questions[data.question].amount}
        </div>
      </div>

      <div className="juiz-choices">
        {randomizeChoices(questions[data.question], choices, 4).choices.map((choice) => {
          return (
        <div className="juiz-choices-choice">
          <div className="juiz-choices-choice-border">
            <div className="juiz-choices-choice-content" onClick={() => {submitChoice(choice)}}>
              <div>{choice}</div>
            </div>
          </div>
        </div>)
        })}
      </div>
    </div>
  )

}
