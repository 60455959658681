import React from "react";
import SideBar from './SideBar'

export const Layout = ({ children }) => {
  return (
    <>
      <div className="theme">
        <div className="layout">
          {children}
        </div>
      </div>
      <SideBar/>
    </>
  )
}
