import React, { Component } from 'react';

import Match from './Match'

export default class Matches extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.matches.map((match) => <Match key={match.id} match={match}/>)}
            </div>
        )
    }
}
