import './Loading.css'

export const Loading = ({enable, style}) => {

  return enable ? (
      <div className="Loading">
        <div className="Content">
          <div className="Icon">
            <div className="Spinner"/>
          </div>
          <div className="Description">
            請稍候
          </div>
        </div>
      </div>
  ) : <></>;
}
