import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';

import { Layout } from '../components/Layout';
import './Exercise.css';

export default function Exercise() {

  const [workoutDuration, setWorkoutDuration] = useState(0);

  const [claimCount, setClaimCount] = useState(0);
  const [claimAllowance, setClaimAllowance] = useState(0);
  const [workouts, setWorkouts] = useState([]);
  const [claims, setClaims] = useState([]);
  const [ready, setReady] = useState(true);

  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => { refreshState() }, [])

  const submitClaim = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/exercise/claims`, {}, {
      withCredentials: true
    })
    .then(response => {
        console.log(response.data)
        // handle success
        refreshState()
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  const submitWorkout = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/exercise/workouts`,{
      duration_in_minutes: workoutDuration
    }, {
      withCredentials: true
    })
    .then(response => {
        refreshState()
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  const refreshState = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/exercise/workouts`, {
      withCredentials: true
    })
    .then(response => {
        console.log(response.data)
        // handle success
        setClaimCount(response.data.claim_count);
        setClaimAllowance(response.data.claim_allowance);
        setWorkouts(response.data.workouts);
        setClaims(response.data.claims);
        setReady(ready)
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  const showWorkout = () => {
    return (
      <>
      <div className="exercise-lives"><a className="icon">👩🐕😈</a>x<a className="amount">{claimAllowance-claimCount}</a></div>

      </>
    )
  }

  const displayWorkout = (workout) => {
    return (
        <div class='workout'>
          <div><div>Workout</div></div>
          <div><div>Duration: {workout.duration_in_minutes}</div></div>
          <div><div>Happened_at: {workout.created_at}</div></div>
        </div>
    )
  }

  const displayClaim = (claim) => {
    return (
        <div class='workout'>
          <div><div>Claim</div></div>
          <div><div>ID: {claim.id}</div></div>
          <div><div>Happened at: {claim.created_at}</div></div>
        </div>
    )
  }

  const handleChange = (event) => {
    setWorkoutDuration(event.target.value)
  }

  return (
    <Layout>
      <div className="exercise">
      {ready && showWorkout()}
        <div>
          <button className="claim-button" onClick={submitClaim}>🌹Claim!🌹</button>
        </div>

        <div className={showDetail ? "exercise-bottom" : "exercise-bottom exercise-bottom-hidden"}>
          {showDetail || <>
            <input type="username" name="duration_in_minutes"  value={workoutDuration} onChange={handleChange} required />
            <button onClick={submitWorkout}>Workout!</button>
          </>}

          <button onClick={() => {setShowDetail(!showDetail)}}>Detail</button>

          {showDetail && (<>
            <div>Claims: {claims.map(displayClaim)}</div>
            <div>Workouts: {workouts.map(displayWorkout)}</div>
          </>)}
        </div>
      </div>
    </Layout>
  );
}
