import './Pound.css';

export default function Pound({amount}) {

  return (
    <div className="pound">
      <div className="amount">
        {(amount < 0 ? "-" : "")}£{Math.abs(amount).toFixed(2)}
      </div>
    </div>
  )

}
