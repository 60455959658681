import { useState, useEffect } from "react";

import { Layout } from '../components/Layout';

import Randomizer from "../randomizer/Randomizer";

export default function Pigou() {

  const [result, setResult] = useState({
    user1: {username: 'Mutantbutton2'},
    user2: {username: 'Metamorphosis'},
    matches: [
      {date: '2022/1/3', user1: 12, user2: 9}
    ]
  });

  const [ matches, setMatches] = useState([])

  const renderMatch = (match) => {
    return (
      <>
        {match.date}
        {match.user1}
        {match.user2}
      </>
    )
  }

  useEffect(() => {
    setMatches(result.matches.map((match) => renderMatch(match)))
  }, [result])

  return (
    <Layout>
          <Randomizer category='Game Mode' options={['Taco', '生死三十秒', '接飛盤', 'Ten Throws']}/>
            <button class="justify-content-center">Add new record</button>
        
              Date
              {result.user1.username}
              {result.user2.username}
        {matches}
    </Layout>
  );
}
