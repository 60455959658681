import { useEffect, useState } from 'react';
import { Redirect, BrowserRouter } from "react-router-dom";
import './styles/App.css';
import Router from './pages/_router'
import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';
import { Layout } from './components/Layout';
import { UserContextProvider } from './contexts/UserContext';

const App = () => {

  const handleLogin = (data) => {
  };

  const handleLogout = () => {
    console.log('Logging out!')
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/logout`)
    window.location.replace(`${process.env.REACT_APP_LOGIN_URL}`);
  };

  return (
    <UserContextProvider logout={handleLogout}>      
      <Toaster/>
      <BrowserRouter>
        <Layout>
          <Router logout={handleLogout} />
        </Layout>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
