import './Button.css'

export const Button = ({
  backgroundColor,
  color,
  backgroundHoverColor,
  onClick,
  children}) => {

  return (
      <div className="common">
        <button className="button" style={{ 'background-color': backgroundColor, color: color }} onClick={onClick}>
          {children}
        </button>
      </div>
  );
}
