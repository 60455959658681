import React, { Component } from "react";

import { Layout } from '../components/Layout';

import './Task.css';

export default function Task({ name }) {

  const [ completed, setCompleted ] = React.useState(false)

  return (
    <div className="task">
      <div className="inner">
      <div className="description">{name}</div>
      <button className="done-button" onClick={() => {setCompleted(!completed)}}>{completed ? '✔️' : '⏳'}</button>
    </div>
    </div>
  );
}
