import { useState, useEffect } from 'react';
import { Button }  from '../components/Core';
import axios from 'axios';

import "./Anniversary.css";

export default function Anniversary() {

  const anniversarySample = {
    name: "New Year",
    description: "Yahoo",
    datetime: "2021-01-01T00:00:00.000Z"
  }
  const [anniversaries, setAnniversaries] = useState([anniversarySample, anniversarySample]);
  const [time, setTime] = useState(Date.now());

  const fetchAnniversaries = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/anniversaries`, {
      withCredentials: true
    })
    .then(response => {
      setAnniversaries(response.data.anniversaries);
    })
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(fetchAnniversaries, []);

  const msToTime = (ms) => {
    var remaining = Math.floor(ms/1000)
    const year = Math.floor (remaining / 365 / 24 / 60 / 60)
    remaining = remaining % (365 * 24 * 60 * 60) 
    const day = Math.floor(remaining / 24 / 60 / 60)
    remaining = remaining % (24 * 60 * 60)
    const hour = Math.floor(remaining / 60 / 60)
    remaining = remaining % (60 * 60)
    const minute = Math.floor(remaining / 60)
    const second = remaining % 60
    return year + " years " + day + " days " + hour + " hours " + minute + " minutes " + second + " seconds"
  }

  return (
      <div className="anniversaries">
        <div className="annivesaries-header">
          Anniversaries
        </div>
        {(anniversaries.map((anniversary) => (
          <div className="anniversary">
            <div className="anniversary-name">
              {anniversary.name}
            </div>
            <div className="anniversary-description">
              {anniversary.description}
            </div>

            <div className="anniversary-date">
              {msToTime(time - Date.parse(anniversary.datetime))}
            </div>
          </div>
        )))}
      </div>
  );
}
