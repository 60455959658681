import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';

import "./SideBar.css";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SideBar() {
  const classes = useStyles();
  const history = useHistory()
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navbarItems = [
   {
      key: 'Homepage',
      path: '/dashboard',
      icon: '🏠',
      text: 'Homepage',
    },
   {
      key: 'Anniversary',
      path: '/anniversary',
      icon: '❤️',
      text: 'Anniversary',
    },


    {
      key: 'Scoreboard',
      path: '/scoreboard',
      icon: '✉',
      text: 'Scoreboard',
    },
    {
      key: 'Todo',
      path: '/todo',
      icon: '📝',
      text: 'Todo',
    },
    {
      key: 'Cook Off',
      path: '/cookOff',
      icon: '🍳',
      text: 'Cook Off',
    },
    {
      key: 'Shit Picker',
      path: '/shitPicker',
      icon: '💩',
      text: 'Shit Picker',
    },
    {
      key: 'Nichijou',
      path: '/nichijou',
      icon: '🌞',
      text: '日常',
    },
    {
      key: 'Exercise',
      path: '/exercise',
      icon: '🌞',
      text: '做愛做的事',
    },
    {
      key: 'Pigou',
      path: '/pigou',
      icon: '🍑',
      text: '屁股王者爭霸戰',
    },
    {
      key: 'Debt',
      path: '/debt',
      icon: '💰',
      text: 'KaChing Swag Swag',
    },
    {
      key: 'Sample',
      path: '/sample',
      icon: '🍆',
      text: 'Sample Page',
    },
    {
      key: 'Juiz',
      path: '/juiz',
      icon: '🇯🇵',
      text: 'Juiz',
    },
    {
      key: 'PrivateMessage',
      path: '/pm',
      icon: '🤫',
      text: '色色細語',
    }
  ]

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    {navbarItems.map(item => (
      <>
      <List>
        <ListItem button key={item.key} onClick={(e) => history.push(item.path)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text}/>
        </ListItem>
      </List>
      <Divider />
      </>
    ))}
    </div>
  );

  return (
    <div className="menu-bar">
      {['left'].map((anchor) => (
        <>
          <div className="menu">
            <button onClick={toggleDrawer(anchor, true)}>Menu</button>
          </div>
          <React.Fragment key={anchor}>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        </>
      ))}
    </div>
  );
}
