import { useContext } from "react";
import { UserContext } from '../contexts/UserContext';

import siteQrCode from '../site-qr-code.png';

export default function Dashboard({ handleLogout }) {

  const user = useContext(UserContext);

  return (
    <div className="dashboard">
      <div>user.name = {user.name}</div>
      <button onClick={handleLogout}>Log out</button>
      <div>
        <img src={siteQrCode} style={{ 'min-width':'200px', 'max-width':'200px' }} />
      </div>
    </div>
   );
}
