import { useState } from "react";

export default function Randomizer({category, options}) {

  const [choice, setChoice] = useState(category)

  const randomize = () => {
    setChoice(options[Math.floor(Math.random()*options.length)])
  }

  return (
    <div class="col">
      <div class="row justify-content-center"> 
        <button onClick={randomize}>{choice}</button>
      </div>
    </div>
  );
}