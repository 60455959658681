import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { Layout } from '../components/Layout';

import Task from "../nichijou/Task";

export default function Nichijou() {

  return (
    <div className="nichijou">
          <Task name="餐一 - 香臭"/>
          <Task name="餐二 - 香臭"/>
          
          <Task name="戈登標準（廚房)"/>
          <Task name="澆花"/>
          <Task name="吸塵"/>
          <Task name="整理"/>
          <Task name="洗衣"/>

          <Task name="早餐 - 米"/>
          <Task name="晚餐 - 米"/>
          <Task name="遛米（一)"/>
          <Task name="遛米（二)"/>
          <Task name="米毛"/>
          <Task name="米牙"/>
    </div>
  );
}
