import { useState, useEffect } from "react";
import axios from 'axios'
import './ScoreBoard.css';

import Player from './Player'
import Matches from './Matches'
import { Button }  from '../../components/Core';
import { Loading } from '../../components/Core/Loading';

export default function ScoreBoard(props) {
  const userId  = props.userId;

  const [reload, setReload] = useState(true);
  const [leftPlayer, setLeftPlayer] = useState({player:{}, kills: 0, deaths: 0, assists: 0, score: 0, matches: []}); 
  const [rightPlayer, setRightPlayer] = useState({player:{}, kills: 0, deaths: 0, assists: 0, score: 0, matches: []});  
  const [totalScore, setTotalScore] = useState(0);
  const [counter, setCounter] = useState(0);
  const [matches, setMatches] = useState([]);

  const [websocket, setWebsocket] = useState(null);

  useEffect(() => {
    // Create a new WebSocket connection when the component mounts
    const ws = new WebSocket(`${process.env.REACT_APP_PUSHIE_URL}/${userId}`);

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      console.log('Message:', event);
      setReload(true);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket closed');
    };

    // Save the WebSocket object in state
    setWebsocket(ws);

    // Close the WebSocket connection when the component unmounts
    return () => {
      if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
        ws.close();
      }
    };
  }, []);
  // const [matches, setMatches] = useState([
  //   {
  //     match_id: "EUW1_6654647235",
  //     timestamp: 1698621912392,
  //     user1: {
  //         champion: "Darius",
  //         kills: 14,
  //         deaths: 13,
  //         assists: 22,
  //         achievements: [
  //             {
  //                 user: {
  //                     id: 1,
  //                     username: "mutantbutton2",
  //                     name: "Mutantbutton2",
  //                     puuid: "W2tO-RIWdl9WQw8k9v0gTcItcTFUm3dn1nfgHkR7RaJM9RYERTxElbT3maWfUQ1m1eRALZBtAZ4_9g"
  //                 },
  //                 amount: 2,
  //                 kill_extra: 4,
  //                 assist_extra: 0,
  //                 kill_multiplier: 0,
  //                 assist_multiplier: 0,
  //                 reason: "Double Kills"
  //             },
  //             {
  //               user: {
  //                   id: 1,
  //                   username: "mutantbutton2",
  //                   name: "Mutantbutton2",
  //                   puuid: "W2tO-RIWdl9WQw8k9v0gTcItcTFUm3dn1nfgHkR7RaJM9RYERTxElbT3maWfUQ1m1eRALZBtAZ4_9g"
  //               },
  //               amount: 2,
  //               kill_extra: 4,
  //               assist_extra: 0,
  //               kill_multiplier: 0,
  //               assist_multiplier: 0,
  //               reason: "Double Kills"
  //           },
  //         ],
  //         total_score: 46
  //     },
  //     user2: {
  //       champion: "Darius",
  //       kills: 14,
  //       deaths: 13,
  //       assists: 22,
  //       achievements: [
  //           {
  //               user: {
  //                   id: 1,
  //                   username: "mutantbutton2",
  //                   name: "Mutantbutton2",
  //                   puuid: "W2tO-RIWdl9WQw8k9v0gTcItcTFUm3dn1nfgHkR7RaJM9RYERTxElbT3maWfUQ1m1eRALZBtAZ4_9g"
  //               },
  //               amount: 2,
  //               kill_extra: 4,
  //               assist_extra: 0,
  //               kill_multiplier: 0,
  //               assist_multiplier: 0,
  //               reason: "Double Kills"
  //           },
  //           {
  //             user: {
  //                 id: 1,
  //                 username: "mutantbutton2",
  //                 name: "Mutantbutton2",
  //                 puuid: "W2tO-RIWdl9WQw8k9v0gTcItcTFUm3dn1nfgHkR7RaJM9RYERTxElbT3maWfUQ1m1eRALZBtAZ4_9g"
  //             },
  //             amount: 2,
  //             kill_extra: 4,
  //             assist_extra: 0,
  //             kill_multiplier: 0,
  //             assist_multiplier: 0,
  //             reason: "Double Kills"
  //         },
  //       ],
  //       total_score: 46
  //   },
  // }]);

  const refreshScore = () => {
    const opponentId = userId === 1 ? 2 : 1
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/scoreboard?left_player_id=${userId}&right_player_id=${opponentId}`, {
      withCredentials: true
    })
    .then(response => {
      setLeftPlayer(response.data.left_player);
      setRightPlayer(response.data.right_player);
      setTotalScore(response.data.total_score);
      setCounter(counter + 1);
      setMatches(response.data.matches);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    }).finally(() => {
      setReload(false);
    })
  };

  const cashout = (event) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/cashouts`, {  
      cashout: {
        request_user_id: userId,
        accept_user_id: userId
      }
    }, {
      withCredentials: true
    })
    .then(response => {
      refreshScore()
    })
    .catch(function (error) {
      console.log(error);
    })
  };

  const cashoutDassai = (event) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/cashouts`, {  
      cashout: {
        request_user_id: userId,
        accept_user_id: userId,
        quantity: 40,
        remark: 'dassai'
      }
    }, {
      withCredentials: true
    })
    .then(response => {
      refreshScore()
    })
    .catch(function (error) {
      console.log(error);
    })
  };
    
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setReload(true);
  //   }, 5000);
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, []);

  useEffect(() => {
    if (reload) {
      refreshScore();
    }
  }, [reload])

  return (
    <div className="scoreboard">
      <div>
        <div>
          <div className="total-score">{totalScore}</div>

      <Loading enable={reload}/>
        </div>
      </div>
      <div className="overview">
        <div className="player-overview">
          <div className="inner">
            <div className="score">{leftPlayer.score}</div>
            <div variant="success" now={totalScore * 0.5} />
            <div><Player player={leftPlayer} /></div>

            <div className="button-bar">
            </div>
          </div>
        </div>
        <div className="player-overview">
          <div className="inner">
            <div className="score">{rightPlayer.score}</div>
            <div variant="danger" now={totalScore * -0.5} />
            <div><Player player={rightPlayer}/></div>
            <div className="button-bar">
            </div>
          </div>
        </div>
      </div>

      <div className="cashout">
        <div className="button-group">
          <div className="each">
              <Button onClick={cashout}>💵</Button>
          </div>
          <div className="each">
              <Button onClick={cashoutDassai}>🍶</Button>
          </div>
        </div>
      </div>
      <Matches matches={matches}/>
    </div>
  );
}
