import axios from 'axios';

export default function Item({item, refresh}) {

  const complete = () => {
    axios.patch(`${process.env.REACT_APP_API_BASE_URL}/todo/items/complete`, {
      id: this.item.id,
    }, {
      withCredentials: true
    })
    .then(response => {
        console.log(response.data)
        // handle success
        refresh()
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    }) 
  }

  return (
    <div className="item inline">
        <div className="description">{item.description}</div>
      <div>
        <button onClick={complete}>{item.completed_at != null ? '✔️' : '⏳'}</button>
      </div>
    </div>
  );
}
