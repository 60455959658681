import { useContext } from "react";

import ScoreBoard from '../components/scorer/ScoreBoard';
import { Layout } from '../components/Layout';
import { UserContext } from '../contexts/UserContext';

export default function Scoreboard() {

  const user = useContext(UserContext);
  return (
    <Layout> 
        <ScoreBoard userId={user.id}/>
    </Layout>
  );
}
