import React, { Component } from 'react';
import './Player.css';

export default function Player({player}) {
  return (
    <div className="player">
      <div className="name">{player.player.username}</div>
      <div className="score">
        <div>K</div>
        <div>D</div>
        <div>A</div>
        <div>C</div>
      </div>
      <div className="score">
        <div>{player.kills}</div>
        <div>{player.deaths}</div>
        <div>{player.assists}</div>
        <div>{player.cashouts}</div>
      </div>
    </div>
  );
}
