import { useState, useEffect } from 'react';
import axios from 'axios';

export default function PrivateMessage() {

  const [input, setInput] = useState("");

  const submitMessage = (event) => {
      if (event.key === 'Enter') {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/privatemessage`, { message: input }, {
          withCredentials: true
        })
        .then(response => {
          setInput("")
        });
      }
  }

  return (
    <>
    <input value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={submitMessage}></input>
    </>
  );
}
